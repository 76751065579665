.container {
  padding: 0 2rem;
  background: #FDFDFD;
  color: #375361;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; 
}

.main {
  /* min-height: 90vh; */
  /* padding: 4rem 0; */
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  /* color: #0070f3; */
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.subtitle {
  line-height: 1;
  font-size: 2.5rem; 
  padding-top: 1rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
  padding-bottom: 10px;
}

.grid a.card:hover {
  color: #A2416B;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  /* color: inherit; */
  text-decoration: none;
  border: 1px solid #D2DADF;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  width: 280px;
}

.card:hover,
.card:focus,
.card:active {
  background-color: #DFE9EB;
  color: '#375361'
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

.pricingcard {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
  height: 420px;
}

.pricingcard .line{
  border-top: 1px solid #375361;
  margin-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.videos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1100px;
  padding: 1.5rem;
}

.viditem {
  margin-top: 2rem;
  padding-left: 0.5rem;
}

.menu {
  color: #A2416B;
  margin: 4rem 1rem;
  line-height: 1.5;
  font-size: 1.2rem;
}

